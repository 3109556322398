import {
  ArrowLeftOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { uploadFile } from "react-s3";
import Prouser from "../../assets/images/user.png";
import AccountSideNav from "../../components/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import { AuthContext } from "../../context/AuthContext";
import { dateString } from "../../helper/functions";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import ProfilepickImg from "../../assets/images/accoint-pro.png";
import EditprofileImg from "../../assets/images/edit-icon.png";
import lang from "../../helper/langHelper";
import { PhoneNumberField } from "../../components/InputField";
import Loader from "../../components/Loader";
import UpdateProfileImage from "../../components/UpdateProfileImage";
import OtpModal from "../../modals/OtpModal";
import dayjs from "dayjs";
import ChatRepository from "../Message/ChatRepository";



window.Buffer = window.Buffer || require("buffer").Buffer;

function AccountIndex() {
  const { request } = useRequest();

  const { isMobile } = useAppContext();
  const {
    setIsLoggedIn,
    setUserProfile,
    userProfile,
    setRefreshUser,
    isLoggedIn,
  } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleImage = (data) => {
    if (data.length && data.length > 0) onUpdate(data[0].url);
  };

  const onUpdate = (image) => {
    let payload = {};
    payload.image = image;
    setLoading(true);
    request({
      url: `${apiPath?.updateProfileImage}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setRefreshUser(true);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
  }, []);

  return (
    <>
      <section className="main">
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} lg={18} xxl={15} className="mx-auto  amt-auto">
              <div className="my-pro-page">
                <h1>{lang("Account Info")}</h1>
                {loading ? (
                  <Loader />
                ) : (
                  <div className="accout-info-orm">
                    <div className="accout-main-44">
                      <div className="account-prof-mg">
                        <div className="main-profilr-fff">
                          <Image
                            src={
                              userProfile?.image ? userProfile?.image : Prouser
                            }
                            alt=""
                          />

                          {!loading ? (
                            <UpdateProfileImage
                              fileType={FileType}
                              imageType={"image"}
                              btnName={""}
                              className="edit-iconee"
                              onChange={(data) => handleImage(data)}
                              isDimension={false}
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <h3>{userProfile?.name ? userProfile?.name : "N/A"}</h3>
                        <h4>
                          {userProfile?.email ? userProfile.email : "N/A"}{" "}
                        </h4>
                      </div>
                      <div className="account-form-discr">
                        <div className="list-main-innfor">
                          <h3>{lang("Name")}</h3>
                          <h5>
                            {userProfile?.name ? userProfile?.name : "N/A"}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Email")} </h3>
                          <h5>
                            {" "}
                            {userProfile?.email
                              ? userProfile?.email
                              : "N/A"}{" "}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Mobile number")} </h3>
                          <h5>
                            {" "}
                            {userProfile?.country_code &&
                            userProfile?.mobile_number
                              ? `+${userProfile?.country_code}-${userProfile?.mobile_number}`
                              : "N/A"}{" "}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("DOB")}</h3>
                          <h5>
                            {userProfile?.dob ? userProfile?.dob : "-"}{" "}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Gender")}</h3>
                          <h5>
                            {" "}
                            {userProfile?.gender === "M"
                              ? "Male"
                              : userProfile?.gender === "F"
                              ? "Female"
                              : "-"}
                          </h5>
                        </div>
                        <div className="list-main-innfor">
                          <h3>{lang("Country")}</h3>
                          <h5>
                            {userProfile?.country_id
                              ? userProfile?.country_id?.name
                              : "-"}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="edit-profille">
                      <button
                        onClick={() => {
                          setShow(true);
                          setSelected(userProfile);
                        }}
                      >
                        {lang("Edit Profile")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {setShow && (
        <AddForm
          show={show}
          hide={() => {
            setShow(false);
            setSelected();
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      <Footer />
    </>
  );
}

const AddForm = ({ show, hide, data, refresh }) => {
  const { setIsLoggedIn, setUserProfile, userProfile, setRefreshUser } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const chatRepo = ChatRepository();


  const { request } = useRequest();

  const getCountry = () => {
    request({
      url: `common/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          console.log("countrydata..", data);
          setCountries(data?.data);
        }
      },
    });
  };
  // const onCreate = (values) => {

  //   const { dob } = values;
  //   let payload = { ...values };
  //   payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
  //   payload.mobile_number = mobileNumber?.mobile_number;
  //   payload.country_code = mobileNumber?.country_code;
  //   console.log(values.name, "NAME ")
  //   console.log(values.image, "Image ")
  //   console.log(payload.mobile_number, "MOBILE NUMBER ")
  //   setLoading(true);
  //   request({
  //     url: `${apiPath?.editProfile}`,
  //     method: "POST",
  //     data: payload,
  //     onSuccess: (data) => {
  //       setLoading(false);
  //       if (data.status) {
  //         ShowToast(data.message, Severty.SUCCESS);
  //         console.log(data, "data....");
  //         setRefreshUser(true);
  //         hide();
  //         if (refresh) refresh();
  //       } else {
  //         ShowToast(data.message, Severty.ERROR);
  //       }
  //     },
  //     onError: (error) => {
  //       ShowToast(error.response.data.message, Severty.ERROR);
  //       setLoading(false);
  //     },
  //   });
  // };

  const onCreate = (values) => {
    const { dob } = values;
    let payload = { ...values };
    payload.dob = dob ? moment(dob).format("YYYY-MM-DD") : null;
    payload.mobile_number = mobileNumber?.mobile_number;
    payload.country_code = mobileNumber?.country_code;

    setLoading(true);
    request({
        url: `${apiPath?.editProfile}`,
        method: "POST",
        data: payload,
        onSuccess: async (data) => {
            setLoading(false);
            if (data.status) {
                ShowToast(data.message, Severty.SUCCESS);
                console.log(data, "data....");
                setRefreshUser(true);
                hide();
                if (refresh) refresh();

                console.log(data._doc.name, "NAME DOC")
                
                await chatRepo.updateGroup(data._doc._id, {
                  name: data._doc.name,
                  mobile_number:  data._doc.mobile_number,
                  image:  data._doc.image,
              });

            } else {
                ShowToast(data.message, Severty.ERROR);
            }
        },
        onError: (error) => {
            ShowToast(error.response.data.message, Severty.ERROR);
            setLoading(false);
        },
    });
};


  useEffect(() => {
    if (!data) return;
    console.log("data", data);

    form.setFieldsValue({
      ...data,
      country_id: data?.country_id?._id,
      dob: data.dob ? moment(data?.dob) : "",
      mobile: data?.country_code + data?.mobile_number || "",
    });
    setMobileNumber({
      mobile_number: data?.mobile_number,
      country_code: data?.country_code,
    });
    getCountry();
  }, [data]);

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value?.slice(data?.dialCode?.length),
    });
  };
  return (
    <>
      <Modal
        visible={show}
        // title="Forgot Password"
        onCancel={hide}
        title="Basic Modal"
        width={749}
        okText="Submit"
        cancelText="Back"
        className="noevent-model "
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
        footer={false}
      >
        <div className="no-active_event">
          <div className="event-backgg-header">
            <h2>{lang("Edit Profile")}</h2>
          </div>
          <div className="noevent-text">
            <Form
              className="noevent-maain-model row"
              id="create"
              form={form}
              onFinish={onCreate}
              layout="vertical"
            >
              <Col span={24} sm={24} md={24}>
                <Form.Item
                  className=""
                  name="name"
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter  Name"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Enter Full Name")} />
                </Form.Item>
              </Col>
              <Col span={24} xl={24} md={24}>
                <Form.Item
                  className="username"
                  // label={lang("Email Address")}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: lang("Please enter a valid email address!"),
                    },
                    {
                      max: 255,
                      message: lang(
                        "Email address not more then 255 characters!"
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please enter email!"),
                    },
                  ]}
                >
                  <Input placeholder={lang("Email address")} />
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item className="username">
                  <PhoneNumberField
                    label={""}
                    name="mobile"
                    placeholder={lang("Enter Phone Number")}
                    cover={{ md: 24 }}
                    colProps={{ sm: 24, span: 24 }}
                    onChange={handleMobileNumberChange}
                    number={mobileNumber?.mobile_number}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item
                  name="dob"
                  className="username"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter dob!"),
                    },
                  ]}
                >
                  <DatePicker
                    defaultValue={dayjs("01-01-2010", "YYYY-MM-DD")}
                    disabledDate={(current) =>
                      current && current >= dayjs("01-01-2010").endOf("day")
                    }
                    placeholder={lang("DOB")}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item
                  // label={lang("Gender")}
                  className="filtter-select"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the gender!"),
                    },
                  ]}
                >
                  <Select
                    className="form-select"
                    placeholder={lang("Select Gender")}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  >
                    <Select.Option value="M">{lang("Male")} </Select.Option>
                    <Select.Option value="F">{lang("Female")} </Select.Option>
                    {/* <Select.Option value="O">Other </Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item
                  className="filtter-select"
                  // label={lang("Country")}
                  name="country_id"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the country!"),
                    },
                  ]}
                >
                  <Select
                    className="form-select"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    autoComplete="off"
                    placeholder={"Select Country"}
                    showSearch
                  >
                    {countries?.map((item) => (
                      <Select.Option
                        key={item._id}
                        label={item.name}
                        value={item._id}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <div className="submit-button-mainnn">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-primary-ft mx-auto "
                  loading={loading}
                >
                  {lang("Submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const BackArrow = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="fillter-sidebar">
      <Link onClick={() => navigate(-1)} className="sidebar-btn">
        <ArrowLeftOutlined />
      </Link>
    </div>
  );
};

export default AccountIndex;
