import { Image, Input, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";
import SearchiconImg from "../../assets/images/ion_search-outline.png";
import Noquotation33Img from "../../assets/images/no-quotation-page-img.png";
import Nodata from "../../assets/images/no-data.png";
import MessagechatImg from "../../assets/images/fluent_chat-32-regular.png";
import BookmarkImg from "../../assets/images/solar_bookmark-outline.png";
import PdflogoImg from "../../assets/images/pdf.png";
import { useNavigate } from "react-router";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import Loader from "../../components/Loader";
import notfound from "../../assets/images/not_found.png";
import useDebounce from "../../hooks/useDebounce";
import lang from "../../helper/langHelper";
import AddBudget from "../Provider/AddBudget";
import {
  ColorCircleComponent,
  PkgAttrShow,
  ServiceAttrShow,
} from "../../helper/functions";
import { useAppContext } from "../../context/AppContext";
import PackageAddBudget from "../Provider/PackageAddBudget";
import AddEvent from "../Event/AddEvent";
import ChatRepository from "../Message/ChatRepository";

function ViewQuotation() {
  const { TabPane } = Tabs;

  return (
    <Main>
      <section className="quotation-section">
        <div className="container for-all-short-w">
          <div class="event-list-hdr">
            <h3>{lang("My Quotes")}</h3>
          </div>

          <Tabs defaultActiveKey="1" className="budget-main-hddr">
            <TabPane tab={lang("Services")} key="1" className="budget-main">
              <div className="add-iemes main-add-for-scroll">
                <ServiceQuotation />
              </div>
            </TabPane>

            <TabPane tab={lang(`Packages`)} key="2" className="budget-main">
              <div className="main-all-div-guestlist">
                <PkgQuotation />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </section>
    </Main>
  );
}

function ServiceQuotation() {
  const navigate = useNavigate();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [quotations, setQuotations] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [showBudget, setShowBudget] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("");
  const [showNewEvent, setShowNewEvent] = useState(false);

  const { language } = useAppContext();
  const chatRepo = ChatRepository();

  const getQuotation = () => {
    setLoading(true);
    request({
      url: `${apiPath.requestQuote}?search=${debouncedSearchText}&type=service`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        setQuotations(data?.docs);
        console.log(data, "getQuotation");
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getQuotation();
  }, [debouncedSearchText, refresh]);

  return (
    <>
      <section>
        <div className="">
          <div className="main-quotation-detailss">
            <div className="pageHeadingSearch">
              <Input.Search
                className="searchInput"
                placeholder={lang("Search by service , provider name...")}
                onChange={(e) => setSearchText(e.target.value)}
                allowClear
              />
            </div>
            <div className="main-accordion-for-all-class-12">
              {loading ? (
                <Loader />
              ) : quotations?.length ? (
                quotations?.map((item) => {
                
                  return (
                    <>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="">
                            <div className="quotation-sub">
                              <div className="quotation-sub-1">
                                <div className="quotation-sub-2">
                                  <div className="quotation-sub-img">
                                    <Image src={item?.service_id?.cover_image ? item?.service_id?.cover_image : item?.service_id?.service_id?.image || notfound} alt="" />
                                  </div>
                                  <div className="quotation-sub-txt">
                                    <h4>{lang("Quotation Subject")}</h4>
                                    <h6>
                                      {language !== "en" && language !== null
                                        ? item?.vendor_id?.[
                                        `${language}_name`
                                        ] ?? item?.vendor_id?.name
                                        : item?.vendor_id?.name}
                                    </h6>
                                    <p>
                                      {language !== "en" && language !== null
                                        ? item?.service_id?.[
                                        `${language}_name`
                                        ] ?? item?.service_id?.name
                                        : item?.service_id?.name}
                                    </p>
                                  </div>
                                </div>
                                <div className="quotation-sub-price">
                                  {item?.price ? (
                                    <h2>JOD {item?.price}</h2>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <ServiceAttrShow quotations={item} />
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="for-q_pagess">
                              <p>
                                {item?.description ? item?.description : ""}
                                {language !== "en" && language !== null
                                  ? item?.[`${language}_description`] ??
                                  item?.description
                                  : item?.description}
                              </p>
                            </div>
                            {item?.image ? (
                              <div className="pdf-main">
                                <Image src={item?.image} />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="for-q_pagess">
                              <p>{item?.quote_reply ? "Response" : ""}</p>
                              <p>
                                {item?.quote_reply?.price
                                  ? `Price :   ${item?.quote_reply?.price}`
                                  : ""}
                              </p>
                              <p>
                                {item?.quote_reply?.message
                                  ? `message : ${item?.quote_reply?.message}`
                                  : ""}
                              </p>
                            </div>
                            {item?.quote_reply?.image ? (
                              <div className="pdf-main">
                                <Image src={item?.quote_reply?.image} />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="add-to-event-my">
                              <button
                                onClick={() => {
                                  setShowBudget(true);
                                  setSelected({
                                    service_id: item?.service_id?._id,
                                    category_id: item?.service_id?.selected_category_id,
                                    vendor_id: item?.vendor_id?._id,
                                    price: item?.quote_reply ? item?.quote_reply?.price : item?.price,
                                    attributes: [],
                                    values: item?.values,
                                    showEvent: true,
                                    addBudgetType: "quotationBudget",
                                  });
                                }}
                              >
                                <img src={BookmarkImg} alt="" />{" "}
                                <span>{lang("Add to My Event")}</span>
                              </button>
                              <div
                                className="masspge-main-dic"
                                onClick={() => {
                                  const groupdId =
                                    JSON.parse(localStorage.getItem("userProfile"))?._id +
                                    "_" +
                                    item?.vendor_id?._id;

                                  let oppositeUserData = {
                                    image: item?.vendor_id?.logo,
                                    mobileNumber: item?.vendor_id?.mobile_number,
                                    name: item?.vendor_id?.name,
                                    unseenCount: 0,
                                  };
                                  let quotationData = {
                                    title: "",
                                    image: "",
                                    budget: "",
                                    description: "",
                                    isQuotation: false,
                                  };

                                  chatRepo.createGroup(
                                    groupdId,
                                    JSON.parse(localStorage.getItem("userProfile"))?._id,
                                    item?.vendor_id?._id,
                                    oppositeUserData,
                                    quotationData
                                  );
                                  navigate("/chat")
                                }}
                              >
                                <img src={MessagechatImg} alt="" />
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })
              ) : (
                <div className="Noquotation33Img">
                  <img src={Noquotation33Img} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {showBudget && (
        <AddBudget
          show={showBudget}
          hide={() => {
            setShowBudget((prev) => !prev);
            setSelected("");
          }}
          selected={selected}
          refresh={() => setRefresh((prev) => !prev)}
          showNewEvent={() => {
            setShowNewEvent(true);
            setShowBudget(false);
          }}
        />
      )}
      {showNewEvent && (
        <AddEvent
          show={showNewEvent}
          hide={() => {
            setShowNewEvent((prev) => !prev);
            setSelected("");
          }}
          budgetData={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
}

function PkgQuotation() {
  const navigate = useNavigate();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [quotations, setQuotations] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);
  const [showBudget, setShowBudget] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("");
  const { language } = useAppContext();
  const [showNewEvent, setShowNewEvent] = useState(false);
  const chatRepo = ChatRepository();


  const getQuotation = () => {
    setLoading(true);
    request({
      url: `${apiPath.requestQuote}?search=${debouncedSearchText}&type=package`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);
        setQuotations(data?.docs);
        console.log(data, "getQuotation");
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getQuotation();
  }, [debouncedSearchText, refresh]);

  return (
    <>
      <section>
        <div className="container">
          <div className="main-quotation-detailss">
            <div className="pageHeadingSearch">
              <Input.Search
                className="searchInput"
                placeholder={lang("Search by package , provider name...")}
                onChange={(e) => setSearchText(e.target.value)}
                allowClear
              />
            </div>
            {loading ? (
              <Loader />
            ) : quotations?.length ? (
              quotations?.map((item) => (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="">
                      <div className="quotation-sub">
                        <div className="quotation-sub-1">
                          <div className="quotation-sub-2">
                            <div className="quotation-sub-img">
                              <Image
                                src={item?.package_id?.cover_image ? item?.package_id?.cover_image
                                    : item?.selected_category_id?.image ?  item?.selected_category_id?.image  :
                                    item?.vendor_id?.logo ? item?.vendor_id?.logo : notfound  }
                                alt=""
                              />
                            </div>
                            <div className="quotation-sub-txt">
                              <h4>{lang("Quotation Subject")}</h4>
                              <h6>
                                {language !== "en" && language !== null
                                  ? item?.vendor_id?.[`${language}_name`] ??
                                  item?.vendor_id?.name
                                  : item?.vendor_id?.name}
                              </h6>
                              <p>
                                {language !== "en" && language !== null
                                  ? item?.package_id?.[`${language}_name`] ??
                                  item?.package_id?.name
                                  : item?.package_id?.name}
                              </p>
                            </div>
                          </div>
                          <div className="quotation-sub-price">
                            {item?.price ? <h2>JOD {item?.price}</h2> : ""}
                          </div>
                        </div>
                        <PkgAttrShow quotations={item} />
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="for-q_pagess">
                        <p>
                          {item?.description ? item?.description : ""}
                          {language !== "en" && language !== null
                            ? item?.[`${language}_description`] ??
                            item?.description
                            : item?.description}
                        </p>
                      </div>
                      {item?.image ? (
                        <div className="pdf-main">
                          <Image src={item?.image} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="for-q_pagess">
                        <p>{item?.quote_reply ? "Provider Response" : ""}</p>
                        <p>
                          {item?.quote_reply?.price
                            ? `Price : ${item?.quote_reply?.price}`
                            : ""}
                        </p>
                        <p>
                          {item?.quote_reply?.message
                            ? `Message : ${item?.quote_reply?.message}`
                            : ""}
                        </p>
                      </div>
                      {item?.quote_reply?.image ? (
                        <div className="pdf-main">
                          <Image src={item?.quote_reply?.image} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="add-to-event-my">
                        <button
                          onClick={() => {
                            setShowBudget(true);
                            setSelected({
                              package_id: item?.package_id?._id,
                              category_id: item?.package_id?.selected_category_id,
                              price: item?.quote_reply?.price ? item?.quote_reply?.price : null,
                              showEvent: true,
                              addBudgetType: "quotePkgBudget",
                            });
                          }}
                        >
                          <img src={BookmarkImg} alt="" />{" "}
                          <span>{lang("Add to My Event")}</span>
                        </button>
                        <div
                          className="masspge-main-dic"
                          onClick={() => {
                            const groupdId =
                              JSON.parse(localStorage.getItem("userProfile"))?._id +
                              "_" +
                              item?.vendor_id?._id;

                            let oppositeUserData = {
                              image: item?.vendor_id?.logo,
                              mobileNumber: item?.vendor_id?.mobile_number,
                              name: item?.vendor_id?.name,
                              unseenCount: 0,
                            };
                            let quotationData = {
                              title: "",
                              image: "",
                              budget: "",
                              description: "",
                              isQuotation: false,
                            };

                            chatRepo.createGroup(
                              groupdId,
                              JSON.parse(localStorage.getItem("userProfile"))?._id,
                              item?.vendor_id?._id,
                              oppositeUserData,
                              quotationData
                            );
                            navigate("/chat")
                          }}
                          // onClick={() => navigate("/chat")}
                        >
                          <img src={MessagechatImg} alt="" />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            ) : (
              <div className="Noquotation33Img">
                <img src={Nodata} />
              </div>
            )}
          </div>
        </div>
      </section>

      {showBudget && (
        <PackageAddBudget
          show={showBudget}
          hide={() => {
            setShowBudget((prev) => !prev);
            setSelected("");
          }}
          showNewEvent={() => {
            setShowNewEvent(true);
            setShowBudget(false);
          }}
          selected={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {showNewEvent && (
        <AddEvent
          show={showNewEvent}
          hide={() => {
            setShowNewEvent((prev) => !prev);
            setSelected("");
          }}
          budgetData={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
    </>
  );
}

export default ViewQuotation;
